export const Android = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="116.04"
      height="30.658"
      viewBox="0 0 116.04 30.658"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Path_1134"
            data-name="Path 1134"
            d="M153.649,340.252l-5.932-3.425-11.443-6.607c-.635-.367-1.268-.145-1.268.622v26.919c0,.575.452.822,1,.5l11.707-6.76,5.932-3.425,5.7-3.292c.669-.387.6-.917.04-1.221S153.649,340.252,153.649,340.252Z"
            transform="translate(-135.006 -330.047)"
            fill="none"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#0067a2" />
          <stop offset="0.403" stopColor="#3d91a8" />
          <stop offset="0.805" stopColor="#74b7ae" />
          <stop offset="1" stopColor="#8ac6b1" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          y1="0.5"
          x2="1"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#50b09b" />
          <stop offset="1" stopColor="#e9e486" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#dc1b24" />
          <stop offset="0.228" stopColor="#c1203b" />
          <stop offset="0.748" stopColor="#8a2b6c" />
          <stop offset="1" stopColor="#753080" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#eb7165" />
          <stop offset="0.003" stopColor="#eb7164" />
          <stop offset="0.675" stopColor="#f9a85e" />
          <stop offset="1" stopColor="#ffbe5c" />
        </linearGradient>
      </defs>
      <g id="Group_1021" data-name="Group 1021" transform="translate(0 2.23)">
        <g id="PLAY" transform="translate(0 0.07)">
          <g id="Group_1001" data-name="Group 1001" clipPath="url(#clip-path)">
            <path
              id="Path_1130"
              data-name="Path 1130"
              d="M135.006,328.285v29.144l14.7-14.572Z"
              transform="translate(-135.006 -328.738)"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_1131"
              data-name="Path 1131"
              d="M153.649,338.637l-5.932-3.425-12.711-7.339v.106l14.7,14.572Z"
              transform="translate(-135.006 -328.432)"
              fill="url(#linear-gradient-2)"
            />
            <path
              id="Path_1132"
              data-name="Path 1132"
              d="M135.006,399.509v.106l12.711-7.339,5.932-3.425-3.947-3.914Z"
              transform="translate(-135.006 -370.818)"
              fill="url(#linear-gradient-3)"
            />
            <path
              id="Path_1133"
              data-name="Path 1133"
              d="M196.087,369.721l-3.947,3.914,3.947,3.914,6.78-3.914Z"
              transform="translate(-177.443 -359.516)"
              fill="url(#linear-gradient-4)"
            />
          </g>
        </g>
        <g
          id="Group_1015"
          data-name="Group 1015"
          transform="translate(88.578 12.589)"
        >
          <path
            id="Path_1135"
            data-name="Path 1135"
            d="M483.312,381.717a3.664,3.664,0,0,1-1.512-.305,2.85,2.85,0,0,1-1.149-.939h-.093a11.408,11.408,0,0,1,.093,1.407v3.487h-1.283v-12.28h1.044l.177,1.16h.062a2.983,2.983,0,0,1,1.153-1.005,3.5,3.5,0,0,1,1.508-.309,3.132,3.132,0,0,1,2.6,1.152,6.137,6.137,0,0,1-.015,6.477A3.14,3.14,0,0,1,483.312,381.717Zm-.186-7.695a2.275,2.275,0,0,0-1.879.719,3.7,3.7,0,0,0-.6,2.289v.286a4.24,4.24,0,0,0,.6,2.556,2.265,2.265,0,0,0,1.91.769,1.987,1.987,0,0,0,1.721-.889,4.22,4.22,0,0,0,.622-2.452,4.062,4.062,0,0,0-.622-2.432A2.049,2.049,0,0,0,483.126,374.022Z"
            transform="translate(-479.368 -369.529)"
            fill="#97999b"
          />
          <path
            id="Path_1136"
            data-name="Path 1136"
            d="M516.228,371.736h-1.284V359.7h1.284Z"
            transform="translate(-505.793 -359.703)"
            fill="#97999b"
          />
          <path
            id="Path_1137"
            data-name="Path 1137"
            d="M531.744,381.607l-.255-1.206h-.062a3.556,3.556,0,0,1-1.265,1.079,3.84,3.84,0,0,1-1.573.282,2.831,2.831,0,0,1-1.976-.65,2.368,2.368,0,0,1-.716-1.848q0-2.567,4.106-2.691l1.439-.047V376a2.146,2.146,0,0,0-.43-1.473,1.763,1.763,0,0,0-1.372-.476,5.588,5.588,0,0,0-2.4.65l-.395-.982a6.031,6.031,0,0,1,1.373-.534,5.944,5.944,0,0,1,1.5-.193,3.23,3.23,0,0,1,2.247.673,2.811,2.811,0,0,1,.731,2.158v5.784Zm-2.9-.9a2.608,2.608,0,0,0,1.883-.657,2.434,2.434,0,0,0,.684-1.841v-.766l-1.284.054a4.67,4.67,0,0,0-2.208.476,1.434,1.434,0,0,0-.677,1.311,1.321,1.321,0,0,0,.422,1.059A1.747,1.747,0,0,0,528.844,380.7Z"
            transform="translate(-513.929 -369.574)"
            fill="#97999b"
          />
          <path
            id="Path_1138"
            data-name="Path 1138"
            d="M555.222,373.533H556.6l1.856,4.833a18.431,18.431,0,0,1,.758,2.39h.062q.1-.4.421-1.35t2.1-5.873h1.377l-3.643,9.651a4.815,4.815,0,0,1-1.265,2.031,2.689,2.689,0,0,1-1.774.6,5.191,5.191,0,0,1-1.16-.131v-1.028a4.486,4.486,0,0,0,.952.093,1.961,1.961,0,0,0,1.887-1.485l.471-1.206Z"
            transform="translate(-535.711 -369.976)"
            fill="#97999b"
          />
        </g>
        <g
          id="Group_1016"
          data-name="Group 1016"
          transform="translate(30.057 10.264)"
        >
          <path
            id="Path_1139"
            data-name="Path 1139"
            d="M383.156,374.751a2.888,2.888,0,0,1-.231,4.91,1.247,1.247,0,0,0-.5.87.972.972,0,0,0,.461.762l.638.5a3.086,3.086,0,0,1,1.488,2.483c0,1.666-1.613,3.35-4.662,3.35-2.571,0-3.811-1.223-3.811-2.536a2.551,2.551,0,0,1,1.365-2.163,7.641,7.641,0,0,1,3.386-.816,1.855,1.855,0,0,1-.532-1.2,1.6,1.6,0,0,1,.177-.693c-.2.018-.391.036-.568.036a2.8,2.8,0,0,1-2.942-2.783,3.189,3.189,0,0,1,1.134-2.376,4.834,4.834,0,0,1,3.174-.975h3.651l-1.135.638Zm-1.259,7.872a2.753,2.753,0,0,0-.407-.018,7.092,7.092,0,0,0-1.862.285,1.991,1.991,0,0,0-1.525,1.825c0,1.258,1.224,2.164,3.121,2.164,1.7,0,2.606-.817,2.606-1.915C383.829,384.057,383.244,383.581,381.9,382.623Zm.514-3.369a1.793,1.793,0,0,0,.442-1.295c0-1.276-.762-3.261-2.233-3.261a1.656,1.656,0,0,0-1.241.585,2.048,2.048,0,0,0-.39,1.312c0,1.188.691,3.155,2.217,3.155A1.815,1.815,0,0,0,382.411,379.254Z"
            transform="translate(-344.47 -369.459)"
            fill="#97999b"
          />
          <path
            id="Path_1140"
            data-name="Path 1140"
            d="M346.391,381.613a4.177,4.177,0,0,1-4.318-4.178,4.386,4.386,0,0,1,4.585-4.3,4.138,4.138,0,0,1,4.229,4.177A4.325,4.325,0,0,1,346.391,381.613Zm2.212-1.434a3.2,3.2,0,0,0,.532-1.965c0-1.558-.743-4.531-2.938-4.531a2.465,2.465,0,0,0-1.592.6,2.678,2.678,0,0,0-.815,2.16c0,1.751.867,4.635,3.01,4.635A2.26,2.26,0,0,0,348.6,380.179Z"
            transform="translate(-318.867 -368.732)"
            fill="#97999b"
          />
          <path
            id="Path_1141"
            data-name="Path 1141"
            d="M309.095,381.613a4.177,4.177,0,0,1-4.319-4.178,4.386,4.386,0,0,1,4.585-4.3,4.138,4.138,0,0,1,4.23,4.177A4.325,4.325,0,0,1,309.095,381.613Zm2.213-1.434a3.206,3.206,0,0,0,.531-1.965c0-1.558-.744-4.531-2.939-4.531a2.466,2.466,0,0,0-1.593.6,2.678,2.678,0,0,0-.814,2.16c0,1.751.867,4.635,3.009,4.635A2.262,2.262,0,0,0,311.307,380.179Z"
            transform="translate(-291.164 -368.732)"
            fill="#97999b"
          />
          <path
            id="Path_1142"
            data-name="Path 1142"
            d="M264.116,369.767l-2.545.587a18.858,18.858,0,0,1-2.936.3c-4.912,0-6.78-3.612-6.78-6.442,0-3.452,2.651-6.654,7.189-6.654a10.241,10.241,0,0,1,2.722.374,6.937,6.937,0,0,1,2.349,1.1l-1.477,1.406-.622.141.445-.712a5.086,5.086,0,0,0-3.808-1.672,4.915,4.915,0,0,0-4.93,5.25,6.293,6.293,0,0,0,6.3,6.494,5.3,5.3,0,0,0,2.26-.445v-2.864l-2.686.142,1.423-.766h3.773l-.462.445a.415.415,0,0,0-.178.284c-.019.161-.035.677-.035.855Z"
            transform="translate(-251.856 -357.164)"
            fill="#97999b"
          />
          <path
            id="Path_1143"
            data-name="Path 1143"
            d="M413.2,368.11c-.588-.054-.71-.16-.71-.855V357.184c0-.038.006-.078.01-.115.072-.622.249-.729.8-1.05h-2.546l-1.335.641h1.36v10.987c0,.355-.07.409-.48.942h3.15l.658-.39C413.807,368.163,413.5,368.145,413.2,368.11Z"
            transform="translate(-368.891 -356.019)"
            fill="#97999b"
          />
          <path
            id="Path_1144"
            data-name="Path 1144"
            d="M433.64,381c-.194.106-.388.231-.582.318a4.116,4.116,0,0,1-1.716.336,3.776,3.776,0,0,1-2.35-.69,4.573,4.573,0,0,1-1.8-3.729,4.005,4.005,0,0,1,4.047-4.082,3.435,3.435,0,0,1,1.821.5,2.925,2.925,0,0,1,1.235,1.749l-4.152,1.679-1.361.106c.441,2.244,1.961,3.551,3.64,3.551a4.566,4.566,0,0,0,2.156-.617Zm-1.66-5.267c.335-.123.512-.23.512-.477a1.753,1.753,0,0,0-1.75-1.52,2.177,2.177,0,0,0-2.032,2.456c0,.3.035.618.054.937Z"
            transform="translate(-382.09 -368.748)"
            fill="#97999b"
          />
          <g
            id="Group_1003"
            data-name="Group 1003"
            transform="translate(52.729 4.408)"
          >
            <path
              id="Path_1145"
              data-name="Path 1145"
              d="M457.373,373.277v1.166h-.141v-1.166h-.386v-.121h.913v.121Z"
              transform="translate(-456.846 -373.156)"
              fill="#97999b"
            />
            <path
              id="Path_1146"
              data-name="Path 1146"
              d="M462.27,374.443v-1.177h-.006l-.359,1.177h-.111l-.362-1.177h0v1.177H461.3v-1.287h.22l.328,1.04h0l.323-1.04h.223v1.287Z"
              transform="translate(-460.153 -373.156)"
              fill="#97999b"
            />
          </g>
        </g>
        <g
          id="Group_1024"
          data-name="Group 1024"
          transform="translate(29.753 -2.23)"
        >
          <path
            id="Path_1170"
            data-name="Path 1170"
            d="M255.082,964.029a16.62,16.62,0,0,1,2.184-.148,4.873,4.873,0,0,1,3.206.877,3.117,3.117,0,0,1,1.127,2.568,3.6,3.6,0,0,1-1.139,2.768,5.283,5.283,0,0,1-3.511,1.025,15.748,15.748,0,0,1-1.867-.085Zm1.034,6.309a7.3,7.3,0,0,0,1.033.043c2.184.01,3.37-1.1,3.37-3.022.012-1.7-1.045-2.748-3.205-2.748a6.5,6.5,0,0,0-1.2.095Z"
            transform="translate(-255.082 -963.553)"
            fill="#97999b"
          />
          <path
            id="Path_1171"
            data-name="Path 1171"
            d="M286.788,976.787a2.567,2.567,0,0,1-2.724-2.631,2.638,2.638,0,0,1,2.818-2.716,2.547,2.547,0,0,1,2.736,2.621,2.648,2.648,0,0,1-2.817,2.726Zm.035-.7a1.82,1.82,0,0,0,1.726-2,1.738,1.738,0,1,0-1.738,2Z"
            transform="translate(-276.609 -969.168)"
            fill="#97999b"
          />
          <path
            id="Path_1172"
            data-name="Path 1172"
            d="M308.957,971.891l.763,2.589c.152.571.305,1.109.411,1.638h.035c.129-.518.317-1.067.505-1.627l.928-2.6h.869l.881,2.557c.211.6.376,1.152.5,1.67h.035a14.166,14.166,0,0,1,.434-1.659l.81-2.568h1.022l-1.832,5.114h-.939l-.869-2.441a16.769,16.769,0,0,1-.505-1.691h-.023a14.02,14.02,0,0,1-.517,1.7l-.915,2.43h-.939l-1.715-5.114Z"
            transform="translate(-294.314 -969.503)"
            fill="#97999b"
          />
          <path
            id="Path_1173"
            data-name="Path 1173"
            d="M343.875,972.94c0-.539-.012-.962-.047-1.384h.916l.058.835h.024a2.152,2.152,0,0,1,1.878-.951,1.945,1.945,0,0,1,2.008,2.177v3.054H347.68v-2.948c0-.824-.341-1.521-1.316-1.521a1.474,1.474,0,0,0-1.385.951,1.254,1.254,0,0,0-.071.433v3.085h-1.033Z"
            transform="translate(-321 -969.168)"
            fill="#97999b"
          />
          <path
            id="Path_1174"
            data-name="Path 1174"
            d="M369.346,962.607h1.033v7.5h-1.033Z"
            transform="translate(-339.954 -962.607)"
            fill="#97999b"
          />
          <path
            id="Path_1175"
            data-name="Path 1175"
            d="M381.156,976.787a2.567,2.567,0,0,1-2.724-2.631,2.638,2.638,0,0,1,2.818-2.716,2.547,2.547,0,0,1,2.735,2.621,2.648,2.648,0,0,1-2.818,2.726Zm.035-.7a1.82,1.82,0,0,0,1.727-2,1.738,1.738,0,1,0-1.738,2Z"
            transform="translate(-346.703 -969.168)"
            fill="#97999b"
          />
          <path
            id="Path_1176"
            data-name="Path 1176"
            d="M407.807,975.445a7.7,7.7,0,0,0,.083,1.225h-.928l-.094-.644h-.035a2.185,2.185,0,0,1-1.738.76,1.549,1.549,0,0,1-1.738-1.469c0-1.236,1.221-1.913,3.416-1.9v-.106c0-.422-.129-1.194-1.291-1.183a2.966,2.966,0,0,0-1.491.38l-.235-.623a4,4,0,0,1,1.879-.444,1.9,1.9,0,0,1,2.172,2.092Zm-1.009-1.384c-1.127-.021-2.407.158-2.407,1.151a.864.864,0,0,0,.963.888,1.433,1.433,0,0,0,1.4-.856.851.851,0,0,0,.047-.3Z"
            transform="translate(-365.217 -969.168)"
            fill="#97999b"
          />
          <path
            id="Path_1177"
            data-name="Path 1177"
            d="M430.815,962.607v6.182c0,.454.013.972.047,1.321h-.927l-.047-.887h-.024a2.126,2.126,0,0,1-1.926,1,2.439,2.439,0,0,1-2.443-2.6,2.55,2.55,0,0,1,2.548-2.747,1.955,1.955,0,0,1,1.715.772h.023v-3.044Zm-1.033,4.47a1.544,1.544,0,0,0-.047-.4,1.493,1.493,0,0,0-1.491-1.067,1.75,1.75,0,0,0-1.7,1.966,1.7,1.7,0,0,0,1.679,1.9,1.538,1.538,0,0,0,1.514-1.109,1.446,1.446,0,0,0,.047-.4Z"
            transform="translate(-381.661 -962.607)"
            fill="#97999b"
          />
          <path
            id="Path_1178"
            data-name="Path 1178"
            d="M463.644,976.787a2.567,2.567,0,0,1-2.724-2.631,2.639,2.639,0,0,1,2.818-2.716,2.547,2.547,0,0,1,2.736,2.621,2.648,2.648,0,0,1-2.817,2.726Zm.036-.7a1.819,1.819,0,0,0,1.726-2,1.737,1.737,0,1,0-1.738,2Z"
            transform="translate(-407.973 -969.168)"
            fill="#97999b"
          />
          <path
            id="Path_1179"
            data-name="Path 1179"
            d="M487.445,972.94c0-.539-.012-.962-.047-1.384h.916l.059.835h.023a2.152,2.152,0,0,1,1.878-.951,1.945,1.945,0,0,1,2.008,2.177v3.054h-1.034v-2.948c0-.824-.34-1.521-1.315-1.521a1.476,1.476,0,0,0-1.386.951,1.26,1.26,0,0,0-.07.433v3.085h-1.033Z"
            transform="translate(-427.641 -969.168)"
            fill="#97999b"
          />
        </g>
      </g>
    </svg>
  );
};
