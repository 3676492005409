import React, { useEffect } from "react";
import { useMediaQuery } from "@chakra-ui/react";

export const ImagesList = ({ sliderImage }) => {
  const ref = React.useRef();
  const [isMobile] = useMediaQuery("(max-width: 481px)");

  const numberIndex = React.useRef(1);

  const slider = () => {
    var x = document.getElementById("slider").querySelectorAll("label");
    var y = document.getElementById("slider").querySelectorAll("input");

    var arr = [];
    for (let i = 0; i < x.length; i++) {
      arr.push(i);
    }

    var newArr = [];

    for (let i = 0; i < x.length; i++) {
      if (x.length <= 3) {
        if (y[0].checked === true) {
          x[0].setAttribute(
            "style",
            "opacity:1 ;  transform: translate3d(0px, 0, 0px);"
          );
          x[1].setAttribute(
            "style",
            `opacity:1 ;transform: translate3d(${
              isMobile ? "100px" : "150px"
            }, 0, -250px);`
          );
          x[2].setAttribute(
            "style",
            `opacity:1 ;transform: translate3d(${
              isMobile ? "-100px" : "-150px"
            }, 0, -250px);`
          );
        }
        if (y[1].checked === true) {
          x[1].setAttribute(
            "style",
            "opacity:1 ;  transform: translate3d(0px, 0, 0px);"
          );
          x[2].setAttribute(
            "style",
            `opacity:1 ;transform: translate3d(${
              isMobile ? "100px" : "150px"
            }, 0, -250px);`
          );
          x[0].setAttribute(
            "style",
            `opacity:1 ;transform: translate3d(${
              isMobile ? "-100px" : "-150px"
            }, 0, -250px);`
          );
        }
        if (y[2].checked === true) {
          x[2].setAttribute(
            "style",
            "opacity:1 ;  transform: translate3d(0px, 0, 0px);"
          );
          x[0].setAttribute(
            "style",
            `opacity:1 ;transform: translate3d(${
              isMobile ? "100px" : "150px"
            }, 0, -250px);`
          );
          x[1].setAttribute(
            "style",
            `opacity:1 ;transform: translate3d(${
              isMobile ? "-100px" : "-150px"
            }, 0, -250px);`
          );
        }
      } else if (y[i].checked) {
        numberIndex.current = i;
        for (let k = i; k < x.length; k++) {
          newArr.push(k);
        }
        for (let index = 0; index < i; index++) {
          newArr.push(index);
        }

        for (let i = 0; i < newArr.length; i++) {
          if (i === 0)
            x[newArr[i]].setAttribute(
              "style",
              "opacity:1 ;  transform: translate3d(0px, 0, 0px);"
            );
          else if (i === 1)
            x[newArr[i]].setAttribute(
              "style",
              `opacity:1 ;transform: translate3d(${
                isMobile ? "100px" : "150px"
              }, 0, -250px);`
            );
          else if (i === 2)
            x[newArr[i]].setAttribute(
              "style",
              `opacity:0.7 ;transform: translate3d(${
                isMobile ? "200px" : "300px"
              }, 0, -550px);`
            );
          else if (i === newArr.length - 1 && newArr.length - 1 > 2)
            x[newArr[i]].setAttribute(
              "style",
              `opacity:1 ;transform: translate3d(${
                isMobile ? "-100px" : "-150px"
              }, 0, -250px);`
            );
          else if (i === newArr.length - 2 && newArr.length - 1 > 3)
            x[newArr[i]].setAttribute(
              "style",
              `opacity:0.7 ;transform: translate3d(${
                isMobile ? "-200px" : "-300px"
              }, 0, -550px);`
            );
          else
            x[newArr[i]].setAttribute(
              "style",
              "opacity:0 ;  transform: translate3d(-500px, 0, -750px);"
            );
        }
      }
    }
  };

  const sliderAuto = () => {
    var x = document.getElementById("slider").querySelectorAll("input");
    setInterval(() => {
      x[
        numberIndex.current + 1 > x.length - 1 ? 0 : numberIndex.current + 1
      ].checked = true;
      x[
        numberIndex.current - 1 < 0 ? x.length - 1 : numberIndex.current - 1
      ].checked = false;
      slider();
    }, 5000);
  };

  useEffect(() => {
    if (sliderImage.length < 3) return;
    if (
      document.getElementById("s1").checked ||
      !document.getElementById("s1").checked
    )
      document.getElementById("s1").checked = true;
    slider();
    sliderAuto();
    // eslint-disable-next-line
  }, [sliderImage]);

  return (
    sliderImage && (
      <section id="slider">
        {sliderImage.map((image, index) => (
          <React.Fragment key={index}>
            <input
              type="radio"
              name="slider"
              id={`s${index + 1}`}
              ref={ref}
              onClick={(e) => slider()}
            />
            <label htmlFor={`s${index + 1}`} id={`slide${index + 1}`}>
              <img src={image._image} alt={image.alt} id={`img${index + 1}`} />
            </label>
          </React.Fragment>
        ))}
      </section>
    )
  );
};
