import axios from "axios";
import React from "react";

export const Contactus = () => {
  const [message, setMessage] = React.useState({
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [sucsses, setSucsses] = React.useState(false);
  const [err, setErr] = React.useState("");

  const submit = (e) => {
    const newMessges = { ...message };
    newMessges[e.target.id] = e.target.value;
    setMessage(newMessges);
  };

  const submitMessage = async (e) => {
    e.preventDefault();
    if (
      message.email.length <= 1 &&
      message.message.length <= 1 &&
      message.phoneNumber.length <= 1
    ) {
      setErr("please fill the required inputs");
      setTimeout(() => {
        setErr("");
      }, 4000);

      return;
    }
    await axios
      .post(
        "https://client.orkabit.com/api/v1/contact-us?domainName=almadinafm.com",
        {
          email: message.email,
          phoneNumber: message.phoneNumber,
          message: message.message,
        }
      )
      .then(function (response) {})
      .catch(function (error) {})
      .finally(function () {
        wordPlay();
        setTimeout(() => {
          setMessage({ email: "", phoneNumber: "", message: "" });
        }, 3000);
      });
  };

  const wordPlay = () => {
    setSucsses(true);
    setTimeout(() => {
      setSucsses(false);
    }, 5000);
  };

  return (
    <form action="" className="form-data" onSubmit={(e) => submitMessage(e)}>
      <input
        type="text"
        name=""
        placeholder="Name"
        id="name"
        value={message.name}
        onChange={(e) => submit(e)}
      ></input>
      <input
        type="text"
        id="email"
        onChange={(e) => submit(e)}
        value={message.email}
        required
        placeholder="E-mail"
      ></input>
      <input
        type="text"
        required
        id="message"
        value={message.message}
        onChange={(e) => submit(e)}
        placeholder="Message"
      ></input>
      <button onClick={submitMessage} id="button">
        {sucsses && err.length === 0 ? (
          "  Thank you for your message "
        ) : err.length !== 0 ? (
          err
        ) : (
          <>Send</>
        )}
      </button>
    </form>
  );
};
