import React, { useRef } from "react";

import { Box, Flex, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faMixcloud,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Android } from "./compomnents/svgAndoid/Android";
import {
  faMapMarkedAlt,
  faMobileAlt,
  faPause,
  faPhoneAlt,
  faVolumeDown,
  faVolumeUp,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { ImagesList } from "./compomnents/ImagesList";
import logo from "./compomnents/images/logo.png";

import { Contactus } from "./compomnents/Contactus";
import axios from "axios";
const Home = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [musicChange, setMusicChange] = useState(false);

  const audioRef = useRef();

  var home = true;
  var about = false;
  var contactUs = false;

  function onClickHome() {
    about = false;
    home = true;
    contactUs = false;
    var someElement = document.getElementById("home");
    someElement.className += " home-plus";

    if (
      document.getElementById("about-us").classList.contains("about-us-plus")
    ) {
      document.getElementById("about-us").classList.remove("about-us-plus");
    }
    if (
      document
        .getElementById("contact-us")
        .classList.contains("contact-us-plus")
    ) {
      document.getElementById("contact-us").classList.remove("contact-us-plus");
    }
    iconColor();
  }

  function onClickAbout() {
    about = true;
    home = false;
    contactUs = false;
    document.getElementById("about-us").className += " about-us-plus";
    if (document.getElementById("home").classList.contains("home-plus")) {
      document.getElementById("home").classList.remove("home-plus");
    }
    if (
      document
        .getElementById("contact-us")
        .classList.contains("contact-us-plus")
    ) {
      document.getElementById("contact-us").classList.remove("contact-us-plus");
    }
    iconColor();
  }
  function onClickContactUs() {
    about = false;
    home = false;
    contactUs = true;
    document.getElementById("contact-us").className += " contact-us-plus";
    if (document.getElementById("home").classList.contains("home-plus")) {
      document.getElementById("home").classList.remove("home-plus");
    }
    if (
      document.getElementById("about-us").classList.contains("about-us-plus")
    ) {
      document.getElementById("about-us").classList.remove("about-us-plus");
    }
    iconColor();
  }

  function iconColor() {
    if (home) {
      document.getElementById("Path_21 home-icon").style.fill = "black";
      document
        .getElementById("first-button")
        .setAttribute(
          "style",
          "opacity:1 ; transform:scale(1.2);font-weight:700"
        );
    }
    if (!home) {
      document.getElementById("Path_21 home-icon").style.fill = "white";
      document
        .getElementById("first-button")
        .setAttribute("style", "opacity:0.5 ; transform:scale(1);");
    }
    if (about) {
      document.getElementById("Path_9").style.fill = "black";
      document
        .getElementById("second-button")
        .setAttribute(
          "style",
          "opacity:1 ; transform:scale(1.2);font-weight:700 "
        );
    }
    if (!about) {
      document.getElementById("Path_9").style.fill = "white";
      document
        .getElementById("second-button")
        .setAttribute("style", "opacity:0.5 ; transform:scale(1);");
    }
    if (contactUs) {
      document.getElementById("Path_10").style.fill = "black";
      document
        .getElementById("third-button")
        .setAttribute(
          "style",
          "opacity:1 ; transform:scale(1.2);font-weight:700"
        );
    }
    if (!contactUs) {
      document.getElementById("Path_10").style.fill = "white";
      document
        .getElementById("third-button")
        .setAttribute("style", "opacity:0.5 ; transform:scale(1);");
    }
  }

  const PlayPauseMusic = () => {
    if (musicChange) audioRef.current.audioEl.current.pause();
    else audioRef.current.audioEl.current.play();
    setMusicChange(!musicChange);
  };

  const changeVolume = (isDown) => {
    if (isDown) audioRef.current.audioEl.current.volume -= 0.1;
    else audioRef.current.audioEl.current.volume += 0.1;
  };

  const getData = async () => {
    await axios
      .get(
        "https://client.orkabit.com/api/v1/page?route=/&domain=almadinafm.com"
      )
      .then(function (response) {
        setData(response.data.page_data);
      })
      .catch(function (error) {})
      .finally(function () {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return data ? (
    <React.Fragment>
      <Flex
        top={loading ? "0" : "-100%"}
        opacity={loading ? "1" : "0"}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className="load"
        position="absolute"
        too="0"
        w="100%"
        h="100vh"
        bg="#a2c9ff"
        zIndex="100"
      >
        <img src={logo} alt="" />
        <Box fontSize="25px" fontWeight="700">
          Al Madina
        </Box>
        <Box className="spans">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </Box>
      </Flex>
      <div className="main-container">
        <header className="main-header">
          <div className="nav-bar-container">
            <ul className="nav-bar">
              <li>
                <button id="second-button" onClick={onClickAbout}>
                  About
                </button>
              </li>
              <li>
                <button id="first-button" onClick={onClickHome}>
                  Home
                </button>
              </li>
              <li>
                <button id="third-button" onClick={onClickContactUs}>
                  Contact Us
                </button>
              </li>
            </ul>
          </div>
          <ReactAudioPlayer
            ref={audioRef}
            src={data?.stream.link}
            controls
            type="audio/mpeg"
            style={{ display: "none" }}
            title="Al Madina FM Syria | FM المدينة"
          />
          <div className="audio-player pc-deisplay">
            <button
              aria-label="mute"
              className="volume-up"
              s
              onClick={() => changeVolume(true)}
            >
              <FontAwesomeIcon icon={faVolumeDown} />
            </button>
            <button
              aria-label="Play"
              className="play-pause"
              onClick={PlayPauseMusic}
            >
              {!musicChange ? (
                <FontAwesomeIcon icon={faPlay} />
              ) : (
                <FontAwesomeIcon icon={faPause} />
              )}
            </button>
            <button
              id="button-minus"
              aria-label="decrease"
              onClick={() => changeVolume(false)}
            >
              <FontAwesomeIcon icon={faVolumeUp} />
            </button>
          </div>
        </header>
        <div className="main-body">
          <div className="about-home-contact-us">
            <div id="home" className="home-plus">
              <div className="we-make-the-hit">
                <h1>The Radio</h1>
              </div>
              {data?.slider || data?.slider?.length > 0 ? (
                <ImagesList sliderImage={data?.slider} />
              ) : null}
            </div>
          </div>
          <div id="about-us" className="about-us">
            <h1>AL Madina FM Syria</h1>
            <h2 dir="rtl" lang="ar">
              إذاعة المدينة
            </h2>
            <p dir="rtl" lang="arabic">
              أول إذاعة خاصة في سورية! انطلق بثها التجريبي في أواخر
              <span style={{ fontWeight: "700" }}>2004</span>، وانطلقت رسمياً في
              شباط <span style={{ fontWeight: "700" }}>2005</span>. <br /> تبث
              إذاعة المدينة اف ام من استديوهاتها في دمشق إلى كل المحافظات
              السورية عبر الموجة
              <span style={{ fontWeight: "700" }}>101.5</span>، وتصل برامجها إلى
              العالم عبر موقعها الالكتروني. <br /> تعمل الإذاعة بأحدث الأجهزة
              والتقنيات وتضم فريق عمل ضخم من إنتاج و تسويق و إعداد وتقنيات. تبثّ
              الإذاعة برامجها باللغة العربية فيما تبث الأغاني بكل اللغات
            </p>
          </div>
          <div id="contact-us" className="contact-us">
            <Contactus />

            <div className="contact-us-info">
              <div className="loaction">
                <FontAwesomeIcon icon={faMapMarkedAlt} />
                مزة فيلات شارع مالك ابن ربيعة
              </div>
              <div className="phone-num">
                <FontAwesomeIcon icon={faPhoneAlt} />
                +963 11 9443
              </div>
              <div className="mobile-num">
                <FontAwesomeIcon icon={faMobileAlt} /> 0945555905
              </div>
            </div>
          </div>
        </div>
        <div className="nav-bar-mobile">
          <div className="audio-player mo-deisplay">
            <button
              aria-label="mute"
              className="volume-up"
              s
              onClick={() => changeVolume(true)}
            >
              <FontAwesomeIcon icon={faVolumeDown} />
            </button>
            <button
              aria-label="Play"
              className="play-pause"
              onClick={PlayPauseMusic}
            >
              {!musicChange ? (
                <FontAwesomeIcon icon={faPlay} />
              ) : (
                <FontAwesomeIcon icon={faPause} />
              )}
            </button>
            <button
              id="button-minus"
              aria-label="decrease"
              onClick={() => changeVolume(false)}
            >
              <FontAwesomeIcon icon={faVolumeUp} />
            </button>
          </div>
          <button onClick={onClickHome}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22.36"
              height="22.349"
              viewBox="0 0 22.36 22.349"
            >
              <path
                id="Path_21 home-icon"
                className="first-icon"
                data-name="Path 21"
                d="M408.719,137.362h.32c.113.032.23.055.339.1a2.046,2.046,0,0,1,.691.505q1.365,1.358,2.728,2.719l.086.083c0-.262,0-.5,0-.741a1.334,1.334,0,0,1,1.324-1.327c.457,0,.913,0,1.37,0a1.18,1.18,0,0,1,.247.027,1.331,1.331,0,0,1,1.062,1.323q0,2.317,0,4.634a.187.187,0,0,0,.06.147q.719.715,1.435,1.433c.251.252.5.506.743.765a1.532,1.532,0,0,1,.366.592c.026.084.044.171.065.256v.32a.136.136,0,0,0-.014.031,1.306,1.306,0,0,1-1.356,1.143h-1.3v.127q0,3.927,0,7.855a1.336,1.336,0,0,1-1.358,1.358h-3.98v-5.933c0-.261,0-.522,0-.783a1.332,1.332,0,0,0-1.3-1.288c-.913,0-1.826,0-2.74,0a1.1,1.1,0,0,0-.229.026,1.332,1.332,0,0,0-1.067,1.329q0,3.265,0,6.529v.12h-.079q-1.966,0-3.932,0a1.269,1.269,0,0,1-.264-.028,1.334,1.334,0,0,1-1.062-1.324q0-3.936,0-7.872v-.114h-1.33a1.325,1.325,0,0,1-1.105-2.083,4.869,4.869,0,0,1,.56-.642q2.8-2.809,5.611-5.61l2.857-2.855c.061-.061.124-.12.184-.181A1.9,1.9,0,0,1,408.719,137.362Z"
                transform="translate(-397.695 -136.862)"
                fill="none"
                stroke="#393939"
                strokeWidth="1"
              />
            </svg>
          </button>
          <button onClick={onClickAbout}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.339"
              height="22.343"
              viewBox="0 0 10.339 22.343"
            >
              <path
                className="second-icon"
                id="Path_9"
                data-name="Path 9"
                d="M-1080.3,337.75a12.457,12.457,0,0,1-1.738.255c-1.008.006-1.4-.416-1.315-1.424a15.01,15.01,0,0,1,.421-2.247c.387-1.564.91-3.1,1.2-4.682.436-2.4-.834-3.9-3.265-3.785a20.185,20.185,0,0,0-3.36.775c-.821.185-.924.742-.885,1.418a14.46,14.46,0,0,1,1.643-.229c1.11-.029,1.482.31,1.361,1.415a26.73,26.73,0,0,1-.623,2.98,45.551,45.551,0,0,0-1.021,4.537c-.222,1.832.973,3.023,2.832,3.192a8.841,8.841,0,0,0,3.793-.734A1.24,1.24,0,0,0-1080.3,337.75Zm-2.291-19.123a2.56,2.56,0,0,0-2.654,2.494,2.594,2.594,0,0,0,2.655,2.511,2.569,2.569,0,0,0,2.68-2.467A2.538,2.538,0,0,0-1082.592,318.628Z"
                transform="translate(1089.751 -318.128)"
                fill="#393939"
                stroke="#393939"
                strokeWidth="1"
              />
            </svg>
          </button>
          <button onClick={onClickContactUs}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.21"
              height="19.138"
              viewBox="0 0 19.21 19.138"
            >
              <path
                className="third-icon"
                id="Path_10"
                data-name="Path 10"
                d="M-445.477,218.243c-2.307-.3-8.334-6.347-8.587-8.616.321-.087.653-.2.994-.266a.979.979,0,0,0,.892-1.017,39.368,39.368,0,0,0-.591-4.165c-.052-.263-.586-.6-.91-.611a3.54,3.54,0,0,0-3.524,2.416,6.223,6.223,0,0,0-.068,3.941c1.272,4.5,4.234,7.668,8.1,10.08a9.886,9.886,0,0,0,5.966,1.679,3.841,3.841,0,0,0,3.823-3.369,1.188,1.188,0,0,0-.927-1.55,20.4,20.4,0,0,0-2.653-.463c-1.583-.172-1.92.079-2.394,1.6C-445.39,218-445.428,218.1-445.477,218.243Z"
                transform="translate(458.058 -203.063)"
                fill="none"
                stroke="#393939"
                strokeWidth="1"
              />
            </svg>
          </button>
          <a href={data.applicationSetting.appStoreLink}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.568"
              height="22.384"
              viewBox="0 0 18.568 22.384"
            >
              <g
                id="Group_5"
                data-name="Group 5"
                transform="translate(1861.117 -234.383)"
              >
                <path
                  id="Path_15"
                  data-name="Path 15"
                  d="M-1860.617,318.819v-1.74c.024-.108.055-.215.071-.324a6.486,6.486,0,0,1,1.418-3.34,4.877,4.877,0,0,1,5.558-1.469c.36.15.728.28,1.091.421a1.8,1.8,0,0,0,1.367,0c.549-.216,1.1-.436,1.663-.607a5.074,5.074,0,0,1,4.575.759,3.792,3.792,0,0,1,1.071,1.128,4.726,4.726,0,0,0-2.275,4.377,4.688,4.688,0,0,0,2.881,4.041c-.258.592-.476,1.178-.761,1.729a11.077,11.077,0,0,1-2.327,3.2,2.6,2.6,0,0,1-2.711.546c-.307-.1-.593-.27-.9-.348a10.342,10.342,0,0,0-1.656-.327,5.041,5.041,0,0,0-2.335.617,2.512,2.512,0,0,1-2.851-.487,5.544,5.544,0,0,1-.474-.471,13.4,13.4,0,0,1-2.99-5.613C-1860.378,320.223-1860.482,319.517-1860.617,318.819Z"
                  transform="translate(0 -71.462)"
                  fill="none"
                  stroke="#393939"
                  strokeWidth="1"
                />
                <path
                  id="Path_16"
                  data-name="Path 16"
                  d="M-1727.753,234.912c-.024.379-.024.723-.072,1.06a4.636,4.636,0,0,1-3.344,3.782c-.076.018-.152.035-.229.047-.694.11-.755.054-.7-.635a4.857,4.857,0,0,1,3.991-4.234C-1728.014,234.919-1727.926,234.921-1727.753,234.912Z"
                  transform="translate(-119.88 0)"
                  fill="none"
                  stroke="#393939"
                  strokeWidth="1"
                />
              </g>
            </svg>
          </a>
          <a href={data.applicationSetting.googlePlayLink}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.97 22.161">
              <g
                id="Group_6"
                data-name="Group 6"
                transform="translate(1958.025 427.302)"
              >
                <path
                  id="Path_17"
                  data-name="Path 17"
                  d="M-1880.822-265h5.724c.221,0,.221,0,.221.214q0,4.088,0,8.175a1.548,1.548,0,0,1-.461,1.182,1.355,1.355,0,0,1-.888.372c-.294.01-.588.009-.882,0-.158-.005-.213.048-.212.209.006.919,0,1.839,0,2.758a1.229,1.229,0,0,1-.366.881,1.314,1.314,0,0,1-1.9-.06,1.181,1.181,0,0,1-.307-.821c.008-.914,0-1.828,0-2.742,0-.217-.006-.223-.224-.223h-1.431c-.205,0-.211.005-.211.218,0,.886-.005,1.773,0,2.659a1.313,1.313,0,0,1-.475,1.057,1.324,1.324,0,0,1-1.8-.145,1.531,1.531,0,0,1-.338-.984c-.013-.858,0-1.717,0-2.575,0-.229,0-.23-.234-.229-.294,0-.588.011-.882,0a1.4,1.4,0,0,1-1.2-.977,3.016,3.016,0,0,1-.078-.814q0-3.963,0-7.926c0-.225,0-.225.225-.225Z"
                  transform="translate(-67.712 -154.703)"
                  fill="none"
                  stroke="#393939"
                  strokeWidth="0.8"
                />
                <path
                  id="Path_18"
                  data-name="Path 18"
                  d="M-1887.928-420.263c.074-.428.13-.829.216-1.224a4.758,4.758,0,0,1,.225-.652,3.617,3.617,0,0,1,.6-1.077,10.891,10.891,0,0,1,.977-1.051,6.545,6.545,0,0,1,.908-.613c.115-.072.156-.157.088-.277q-.4-.7-.8-1.4a.259.259,0,0,1-.014-.03c-.037-.1-.116-.221.024-.283.122-.054.188.023.247.154.115.256.275.492.415.737s.272.476.406.715c.06.109.115.139.256.09a8.507,8.507,0,0,1,1.314-.38,9.481,9.481,0,0,1,1.369-.082,3.168,3.168,0,0,1,.672.058c.38.074.756.17,1.132.265a1.408,1.408,0,0,1,.365.137c.165.1.242,0,.3-.117.25-.459.491-.922.74-1.382.055-.1.1-.24.259-.2.045.012.112.118.1.149-.106.234-.229.461-.35.689-.174.327-.353.651-.522.981a.163.163,0,0,0,.038.153,5.173,5.173,0,0,1,1.635,1.277,5.2,5.2,0,0,1,.954,1.567,5.355,5.355,0,0,1,.337,1.488c.025.326.039.325-.3.325h-11.347C-1887.734-420.248-1887.8-420.256-1887.928-420.263Zm9.235-2.617a.5.5,0,0,0-.521-.513.56.56,0,0,0-.559.556.585.585,0,0,0,.539.5A.512.512,0,0,0-1878.693-422.88Zm-6.474.034a.471.471,0,0,0,.506.5.543.543,0,0,0,.54-.527.512.512,0,0,0-.549-.519C-1885.011-423.39-1885.169-423.217-1885.167-422.846Z"
                  transform="translate(-66.606)"
                  fill="none"
                  stroke="#393939"
                  strokeWidth="0.8"
                />
                <path
                  id="Path_19"
                  data-name="Path 19"
                  d="M-1605.177-267.334c0,.957.031,1.916-.016,2.871a1.165,1.165,0,0,1-.578.951,1.339,1.339,0,0,1-.788.223,1.289,1.289,0,0,1-.764-.3,1.357,1.357,0,0,1-.49-1.143q-.005-2.632,0-5.264a1.593,1.593,0,0,1,.258-.858,1.138,1.138,0,0,1,.473-.41,1.084,1.084,0,0,1,.85-.116,1.3,1.3,0,0,1,.714.421,4.279,4.279,0,0,1,.256.462,1.239,1.239,0,0,1,.093.638q0,1.262,0,2.524Z"
                  transform="translate(-334.289 -148.571)"
                  fill="none"
                  stroke="#393939"
                  strokeWidth="0.8"
                />
                <path
                  id="Path_20"
                  data-name="Path 20"
                  d="M-1957.619-267.21c0-.946-.023-1.894.01-2.839a1.16,1.16,0,0,1,.683-1.025,1.449,1.449,0,0,1,.6-.184,1.234,1.234,0,0,1,.854.316,1.406,1.406,0,0,1,.46,1.117c-.009,1.815,0,3.631-.007,5.446a1.188,1.188,0,0,1-.723,1.116,1.252,1.252,0,0,1-1.543-.306,1.3,1.3,0,0,1-.336-.835C-1957.612-265.34-1957.618-266.275-1957.619-267.21Z"
                  transform="translate(0 -148.727)"
                  fill="none"
                  stroke="#393939"
                  strokeWidth="0.8"
                />
              </g>
            </svg>
          </a>
        </div>
        <footer className="foooter">
          {/*          
          <AdSense.Google
            client="ca-pub-7292810486004926"
            slot="7806394673"
            style={{position:'absolute',top:0,left:0, width: 300, height: 200, float: "left" }}
            format=""
          /> */}
          <div className="socail-media">
            <div className="orka">
              <p>
                Almadina FM , Copyright © {new Date().getFullYear()}
                <br />
              </p>
              <a target="_new" href="https://orkabit.com/">
                <h1>
                  <span>powered by</span> OrkaBit
                </h1>
              </a>
            </div>
            <ul>
              <li className="number-1">
                <a
                  target="_new"
                  href="https://www.facebook.com/almadinafmsyria"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </li>
              <li className="number-2">
                <a target="_new" href="https://www.twitter.com/almadinafmsyria">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
              <li className="number-3">
                <a
                  target="_new"
                  href="https://www.youtube.com/channel/UCuYxPAlC82bDQXfSz9J0MCg"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
              </li>
              <li className="number-4">
                <a
                  target="_new"
                  href="https://www.instagram.com/almadinafmsyria/"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li className="number-5">
                <a
                  target="_new"
                  href="http://www.mixcloud.com/almadinafmsyria/"
                >
                  <FontAwesomeIcon icon={faMixcloud} />
                </a>
              </li>
              <li className="number-6">
                <a target="-new" href="tel:0944002018">
                  <FontAwesomeIcon icon={faWhatsapp} />
                </a>
              </li>
            </ul>
          </div>
          <div className="app-download">
            <a href={data.applicationSetting.appStoreLink}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="115.768"
                height="30.249"
                viewBox="0 0 115.768 30.249"
              >
                <g
                  id="Group_1020"
                  data-name="Group 1020"
                  transform="translate(0 0)"
                >
                  <g
                    id="Group_1018"
                    data-name="Group 1018"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_1160"
                      data-name="Path 1160"
                      d="M157.63,1005a21.743,21.743,0,0,1-1.965,3.669c-1.277,1.943-3.075,4.362-5.3,4.383-1.981.018-2.489-1.289-5.177-1.275s-3.249,1.3-5.23,1.279c-2.229-.021-3.933-2.205-5.21-4.148-3.569-5.433-3.942-11.809-1.741-15.2a7.9,7.9,0,0,1,6.352-3.819c2.362,0,3.848,1.3,5.8,1.3,1.894,0,3.049-1.3,5.78-1.3a7.708,7.708,0,0,1,5.811,3.068A6.649,6.649,0,0,0,157.63,1005Z"
                      transform="translate(-131.637 -982.804)"
                      fill="#97999b"
                    />
                    <g
                      id="Group_1007"
                      data-name="Group 1007"
                      transform="translate(12.493)"
                    >
                      <path
                        id="Path_1161"
                        data-name="Path 1161"
                        d="M184.942,967.266a6.631,6.631,0,0,0,1.472-4.911,7.165,7.165,0,0,0-4.624,2.487,6.155,6.155,0,0,0-1.511,4.79A6.046,6.046,0,0,0,184.942,967.266Z"
                        transform="translate(-180.207 -962.355)"
                        fill="#97999b"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_1019"
                    data-name="Group 1019"
                    transform="translate(31.753 0.066)"
                  >
                    <g
                      id="Group_1009"
                      data-name="Group 1009"
                      transform="translate(0 10.516)"
                    >
                      <path
                        id="Path_1162"
                        data-name="Path 1162"
                        d="M260.194,1004.42l-5.112,15.049h2.341l1.526-4.621h4.95l1.57,4.621h2.406l-5.134-15.049Zm1.213,2.474c.173.672.359,1.369.6,2.123l1.378,3.99h-3.921l1.359-4.016C261.028,1008.34,261.224,1007.637,261.407,1006.894Z"
                        transform="translate(-255.082 -1004.18)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1163"
                        data-name="Path 1163"
                        d="M313.807,1019.586a4.26,4.26,0,0,0-3.62,1.737l-.091-1.5h-2.082l.012.191c.062.932.087,1.959.087,3.432v11.6h2.277v-5.413a4,4,0,0,0,3.112,1.337,4.694,4.694,0,0,0,3.45-1.467,6.22,6.22,0,0,0,1.523-4.379,6.057,6.057,0,0,0-1.323-4A4.332,4.332,0,0,0,313.807,1019.586Zm-.567,1.885c2.03,0,2.938,1.881,2.938,3.743,0,2.4-1.159,3.9-3.025,3.9a2.76,2.76,0,0,1-2.682-2.113,2.9,2.9,0,0,1-.081-.737v-1.831a3.425,3.425,0,0,1,.072-.562c.011-.062.024-.124.033-.185A2.883,2.883,0,0,1,313.24,1021.471Z"
                        transform="translate(-294.399 -1015.445)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1164"
                        data-name="Path 1164"
                        d="M359.388,1019.586a4.262,4.262,0,0,0-3.62,1.737l-.091-1.5H353.6l.012.191c.062.933.088,1.959.088,3.432v11.6h2.277v-5.413a4,4,0,0,0,3.111,1.337,4.7,4.7,0,0,0,3.452-1.467,6.228,6.228,0,0,0,1.523-4.379,6.058,6.058,0,0,0-1.324-4A4.331,4.331,0,0,0,359.388,1019.586Zm-.567,1.885c2.029,0,2.937,1.881,2.937,3.743,0,2.4-1.16,3.9-3.024,3.9a2.76,2.76,0,0,1-2.682-2.113,2.907,2.907,0,0,1-.08-.737v-1.831a3.378,3.378,0,0,1,.073-.564c.011-.062.023-.123.033-.183A2.881,2.881,0,0,1,358.822,1021.471Z"
                        transform="translate(-328.256 -1015.445)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1165"
                        data-name="Path 1165"
                        d="M417.565,1010.1c-2.125-.823-2.806-1.47-2.806-2.666,0-1.007.8-2.022,2.588-2.022a5.175,5.175,0,0,1,2.673.671l.193.119.643-1.9-.127-.074a6.6,6.6,0,0,0-3.316-.744c-2.9,0-4.932,1.714-4.932,4.167,0,1.979,1.27,3.362,4,4.353,1.932.722,2.652,1.462,2.652,2.73,0,1.436-1.11,2.327-2.894,2.327a6.231,6.231,0,0,1-3.282-.931l-.2-.127-.611,1.944.112.074a7.8,7.8,0,0,0,3.848.989c3.913,0,5.3-2.386,5.3-4.429C421.406,1012.48,420.294,1011.181,417.565,1010.1Z"
                        transform="translate(-371.744 -1003.488)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1166"
                        data-name="Path 1166"
                        d="M452.913,1010.439l-2.233.7v1.892h-1.635v1.82h1.635v5.574a4.26,4.26,0,0,0,.788,2.889,2.918,2.918,0,0,0,2.2.865,5.366,5.366,0,0,0,1.76-.272l.128-.045-.109-1.823-.218.067a3.428,3.428,0,0,1-1.059.122c-.882,0-1.259-.558-1.259-1.869v-5.509h2.747v-1.82h-2.747Z"
                        transform="translate(-399.153 -1008.651)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1167"
                        data-name="Path 1167"
                        d="M481.072,1019.586c-3.186,0-5.41,2.378-5.41,5.781,0,3.3,2.153,5.606,5.236,5.606h.021c2.606,0,5.41-1.816,5.41-5.8a5.752,5.752,0,0,0-1.451-4.027A5.067,5.067,0,0,0,481.072,1019.586Zm-.066,1.8c2.048,0,2.981,2,2.981,3.853,0,2.282-1.273,3.939-3.024,3.939h-.022c-1.727,0-2.98-1.629-2.98-3.873C477.961,1023.352,478.9,1021.383,481.006,1021.383Z"
                        transform="translate(-418.923 -1015.445)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1168"
                        data-name="Path 1168"
                        d="M527.878,1019.692l-.136-.035a2.187,2.187,0,0,0-.566-.071,3.249,3.249,0,0,0-2.855,1.833l-.067-1.593h-2.043l.013.191c.061.9.086,1.913.086,3.279l-.021,7.438h2.277v-5.8a5.857,5.857,0,0,1,.063-.882,2.517,2.517,0,0,1,2.4-2.294,5.829,5.829,0,0,1,.611.038l.244.025Z"
                        transform="translate(-453.499 -1015.445)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1169"
                        data-name="Path 1169"
                        d="M549.279,1019.5c-3.031,0-5.148,2.422-5.148,5.889,0,3.339,2.106,5.5,5.366,5.5a8.731,8.731,0,0,0,3.6-.692l.137-.062-.438-1.7-.19.077a7.062,7.062,0,0,1-2.852.51,3.165,3.165,0,0,1-3.41-3.3l7.349.022.03-.145a5.056,5.056,0,0,0,.068-.95C553.8,1022.276,552.613,1019.5,549.279,1019.5Zm-.151,1.732a2.247,2.247,0,0,1,1.671.638,3.241,3.241,0,0,1,.784,2.12H546.39A2.906,2.906,0,0,1,549.127,1021.234Z"
                        transform="translate(-469.78 -1015.383)"
                        fill="#97999b"
                      />
                    </g>
                    <g id="Group_1010" data-name="Group 1010">
                      <path
                        id="Path_1170"
                        data-name="Path 1170"
                        d="M255.082,964.029a16.62,16.62,0,0,1,2.184-.148,4.873,4.873,0,0,1,3.206.877,3.117,3.117,0,0,1,1.127,2.568,3.6,3.6,0,0,1-1.139,2.768,5.283,5.283,0,0,1-3.511,1.025,15.748,15.748,0,0,1-1.867-.085Zm1.034,6.309a7.3,7.3,0,0,0,1.033.043c2.184.01,3.37-1.1,3.37-3.022.012-1.7-1.045-2.748-3.205-2.748a6.5,6.5,0,0,0-1.2.095Z"
                        transform="translate(-255.082 -963.553)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1171"
                        data-name="Path 1171"
                        d="M286.788,976.787a2.567,2.567,0,0,1-2.724-2.631,2.638,2.638,0,0,1,2.818-2.716,2.547,2.547,0,0,1,2.736,2.621,2.648,2.648,0,0,1-2.817,2.726Zm.035-.7a1.82,1.82,0,0,0,1.726-2,1.738,1.738,0,1,0-1.738,2Z"
                        transform="translate(-276.609 -969.168)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1172"
                        data-name="Path 1172"
                        d="M308.957,971.891l.763,2.589c.152.571.305,1.109.411,1.638h.035c.129-.518.317-1.067.505-1.627l.928-2.6h.869l.881,2.557c.211.6.376,1.152.5,1.67h.035a14.166,14.166,0,0,1,.434-1.659l.81-2.568h1.022l-1.832,5.114h-.939l-.869-2.441a16.769,16.769,0,0,1-.505-1.691h-.023a14.02,14.02,0,0,1-.517,1.7l-.915,2.43h-.939l-1.715-5.114Z"
                        transform="translate(-294.314 -969.503)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1173"
                        data-name="Path 1173"
                        d="M343.875,972.94c0-.539-.012-.962-.047-1.384h.916l.058.835h.024a2.152,2.152,0,0,1,1.878-.951,1.945,1.945,0,0,1,2.008,2.177v3.054H347.68v-2.948c0-.824-.341-1.521-1.316-1.521a1.474,1.474,0,0,0-1.385.951,1.254,1.254,0,0,0-.071.433v3.085h-1.033Z"
                        transform="translate(-321 -969.168)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1174"
                        data-name="Path 1174"
                        d="M369.346,962.607h1.033v7.5h-1.033Z"
                        transform="translate(-339.954 -962.607)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1175"
                        data-name="Path 1175"
                        d="M381.156,976.787a2.567,2.567,0,0,1-2.724-2.631,2.638,2.638,0,0,1,2.818-2.716,2.547,2.547,0,0,1,2.735,2.621,2.648,2.648,0,0,1-2.818,2.726Zm.035-.7a1.82,1.82,0,0,0,1.727-2,1.738,1.738,0,1,0-1.738,2Z"
                        transform="translate(-346.703 -969.168)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1176"
                        data-name="Path 1176"
                        d="M407.807,975.445a7.7,7.7,0,0,0,.083,1.225h-.928l-.094-.644h-.035a2.185,2.185,0,0,1-1.738.76,1.549,1.549,0,0,1-1.738-1.469c0-1.236,1.221-1.913,3.416-1.9v-.106c0-.422-.129-1.194-1.291-1.183a2.966,2.966,0,0,0-1.491.38l-.235-.623a4,4,0,0,1,1.879-.444,1.9,1.9,0,0,1,2.172,2.092Zm-1.009-1.384c-1.127-.021-2.407.158-2.407,1.151a.864.864,0,0,0,.963.888,1.433,1.433,0,0,0,1.4-.856.851.851,0,0,0,.047-.3Z"
                        transform="translate(-365.217 -969.168)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1177"
                        data-name="Path 1177"
                        d="M430.815,962.607v6.182c0,.454.013.972.047,1.321h-.927l-.047-.887h-.024a2.126,2.126,0,0,1-1.926,1,2.439,2.439,0,0,1-2.443-2.6,2.55,2.55,0,0,1,2.548-2.747,1.955,1.955,0,0,1,1.715.772h.023v-3.044Zm-1.033,4.47a1.544,1.544,0,0,0-.047-.4,1.493,1.493,0,0,0-1.491-1.067,1.75,1.75,0,0,0-1.7,1.966,1.7,1.7,0,0,0,1.679,1.9,1.538,1.538,0,0,0,1.514-1.109,1.446,1.446,0,0,0,.047-.4Z"
                        transform="translate(-381.661 -962.607)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1178"
                        data-name="Path 1178"
                        d="M463.644,976.787a2.567,2.567,0,0,1-2.724-2.631,2.639,2.639,0,0,1,2.818-2.716,2.547,2.547,0,0,1,2.736,2.621,2.648,2.648,0,0,1-2.817,2.726Zm.036-.7a1.819,1.819,0,0,0,1.726-2,1.737,1.737,0,1,0-1.738,2Z"
                        transform="translate(-407.973 -969.168)"
                        fill="#97999b"
                      />
                      <path
                        id="Path_1179"
                        data-name="Path 1179"
                        d="M487.445,972.94c0-.539-.012-.962-.047-1.384h.916l.059.835h.023a2.152,2.152,0,0,1,1.878-.951,1.945,1.945,0,0,1,2.008,2.177v3.054h-1.034v-2.948c0-.824-.34-1.521-1.315-1.521a1.476,1.476,0,0,0-1.386.951,1.26,1.26,0,0,0-.07.433v3.085h-1.033Z"
                        transform="translate(-427.641 -969.168)"
                        fill="#97999b"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </a>
            <a href={data.applicationSetting.googlePlayLink}>
              <Android />
            </a>
          </div>
        </footer>
      </div>
    </React.Fragment>
  ) : (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      height="100vh"
      width="100vw"
    >
      <Spinner color="#2E52A4" />
    </Box>
  );
};

export default Home;
<script
  async
  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1296582538200313"
  crossorigin="anonymous"
></script>;
<script
  async
  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1296582538200313"
  crossorigin="anonymous"
></script>;
